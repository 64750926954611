<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <v-row>
      <v-col cols="12">
        <card-school :school="school" :fetch="schoolFetch" :edit="(this.user.level >= 5)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" pa-0>
        <v-row>
          <v-col cols="12" md="6">
            <card-list-dashboard
              heading="Coordenadores"
              :fetch="coordinatorsFetch"
              :items="coordinators"
              :footerUrl="`/school/list/${this.$route.params.id}/coordinators`"
              footerText="Visualizar todos os coordenadores"
              :show-options="true"
              :options="[
                {
                  title: 'Gerenciar coordenadores',
                  url: `/school/people/${this.$route.params.id}/coordinators`
                }
              ]"
            >
              <template v-slot:options>
                teste
              </template>
            </card-list-dashboard>
            <card-list-dashboard
            class="mt-8"
              heading="Diretores"
              :items="principals"
              :fetch="principalsFetch"
              :footerUrl="`/school/list/${this.$route.params.id}/principals`"
              footerText="Visualizar todos os diretores"
              :show-options="true"
              :options="[
                {
                  title: 'Gerenciar diretores',
                  url: `/school/people/${this.$route.params.id}/principals`
                }
              ]"
            ></card-list-dashboard>
          </v-col>
          <v-col cols="12" md="6">
            <card-list-dashboard
              heading="Turmas"
              :items="courses"
              :link-base="`/course/${$route.params.id}/`"
              :fetch="coursesFetch"
              :footerUrl="`/school/course/${this.$route.params.id}`"
              footerText="Visualizar todas as turmas"
            ></card-list-dashboard>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import md5 from '@/libs/md5'

export default {
  ///
  data: () => ({
    page: {
      title: 'Escola'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school',
        exactPath: true
      }
    ],
    school: {},
    coordinators: [],
    principals: [],
    courses: [],
    schoolFetch: false,
    coursesFetch: false,
    coordinatorsFetch: false,
    principalsFetch: false
  }),
  methods: {
    ...mapMutations(['setLoading']),
    formatCourses (courses) {
      const arr = []
      if (!courses.length) return arr
      courses.forEach((value, index) => {
        const obj = {
          ...value,
          data: {
            ...value.data,
            avatar: `https://gravatar.com/avatar/${md5(value.metadata.id)}?s=80&d=identicon&r=x`
          }
        //   selected: false
        }
        arr.push(obj)
      })
      return arr
    },
    async getSchoolData (organizationId) {
      try {
        const classResponse = await this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.school = classResponse.data
        this.schoolFetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {

      }
    },
    async getCourses (id) {
      try {
        await this.$axios.get(`/organizations/${id}/schools/${this.$route.params.id}/courses?per_page=12`)
          .then(response => {
            this.courses = this.formatCourses(response.data.items)
          })
      } catch (error) {
      } finally {
        this.coursesFetch = true
      }
    },
    async getPeople (id) {
      try {
        await this.$axios.get(`/organizations/${id}/schools/${this.$route.params.id}/people?roles[]=SCHOOL_PEDAGOGICAL_COORDINATOR&roles[]=PRINCIPAL`)
          .then(response => {
            this.principals = response.data.items.filter(person => person.data.roles.includes('PRINCIPAL'))
            this.coordinators = response.data.items.filter(person => person.data.roles.includes('SCHOOL_PEDAGOGICAL_COORDINATOR'))
          })
      } catch (error) {
      } finally {
        this.coordinatorsFetch = true
        this.principalsFetch = true
      }
    },
    handleClick (value) {
      this.$router.push({ path: '/class' })
    }
  },
  async mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (id) {
      await this.getSchoolData(id)
      this.getPeople(id)
      this.getCourses(id)
    } else {
      this.$store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma organização' })
    }
    // this.getClassData()
    this.breadcrumbs.push(
      {
        text: this.school.data.name,
        disabled: false,
        to: `/school/${this.$route.params.id}`
      }
    )
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>
