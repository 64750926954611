var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('base-breadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('card-school',{attrs:{"school":_vm.school,"fetch":_vm.schoolFetch,"edit":(this.user.level >= 5)}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","pa-0":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('card-list-dashboard',{attrs:{"heading":"Coordenadores","fetch":_vm.coordinatorsFetch,"items":_vm.coordinators,"footerUrl":`/school/list/${this.$route.params.id}/coordinators`,"footerText":"Visualizar todos os coordenadores","show-options":true,"options":[
              {
                title: 'Gerenciar coordenadores',
                url: `/school/people/${this.$route.params.id}/coordinators`
              }
            ]},scopedSlots:_vm._u([{key:"options",fn:function(){return [_vm._v(" teste ")]},proxy:true}])}),_c('card-list-dashboard',{staticClass:"mt-8",attrs:{"heading":"Diretores","items":_vm.principals,"fetch":_vm.principalsFetch,"footerUrl":`/school/list/${this.$route.params.id}/principals`,"footerText":"Visualizar todos os diretores","show-options":true,"options":[
              {
                title: 'Gerenciar diretores',
                url: `/school/people/${this.$route.params.id}/principals`
              }
            ]}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('card-list-dashboard',{attrs:{"heading":"Turmas","items":_vm.courses,"link-base":`/course/${_vm.$route.params.id}/`,"fetch":_vm.coursesFetch,"footerUrl":`/school/course/${this.$route.params.id}`,"footerText":"Visualizar todas as turmas"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }